import issueOneData from '../../data/issueOne.json'

const getDefaultState = () => {
  return {
    cause: issueOneData[0].title,
    baseAmount: 5000,
    annualAmount: 0,
    contributionInterval: 'Annually',
    example: 0,
    regularContribution: false
  }
}

const state = getDefaultState()

const getDefaultStateButCause = () => {
  return {
    baseAmount: 5000,
    annualAmount: 0,
    contributionInterval: 'Annually',
    regularContribution: false
  }
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setCause(state: any, cause: String) {
    state.cause = cause
  },
  setBaseAmount(state: any, payload: number) {
    state.baseAmount = payload
  },
  setAnnualAmount(state: any, annualAmount: Number) {
    state.annualAmount = annualAmount
  },
  setContributionIntervals(state: any, contributionInterval: String) {
    state.contributionInterval = contributionInterval
  },
  resetState(state: any) {
    Object.assign(state, getDefaultState())
  },
  resetAllValuesButCause(state: any) {
    Object.assign(state, getDefaultStateButCause())
  },
  setExample(state: any, example: Number) {
    state.example = example
  },
  setRegularContribution(state: any, value: boolean) {
    state.regularContribution = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
