import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/causes',
    name: 'causes',
    component: () => import('../views/Causes.vue')
  },
  {
    path: '/impact',
    name: 'impact',
    component: () => import('../views/ImpactPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
