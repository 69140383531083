const getDefaultState = () => {
  return {
    sendInfo: true,
    showSuccessSection: false,
    showFailureSection: false
  }
}

const state = getDefaultState()

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setSendInfo(state: any, info: Boolean) {
    state.sendInfo = info
  },
  setShowSuccessSection(state: any, success: Boolean) {
    state.showSuccessSection = success
  },
  setShowFailureSection(state: any, success: Boolean) {
    state.showFailureSection = success
  },
  resetAllValues(state: any) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
