import Vue from 'vue'
import Vuex from 'vuex'
import impact from './modules/impact'
import connect from './modules/connect'
// import createLogger from "../../../src/plugins/logger";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    impact,
    connect
  },
  strict: debug
})
