










import Vue from 'vue'
import VueCompositionAPI, { defineComponent } from '@vue/composition-api'

Vue.use(VueCompositionAPI)

export default defineComponent({
  name: 'App',
  setup() {
    return {
      links: [
        {
          label: 'Home',
          url: '/'
        },
        {
          label: 'Causes',
          url: '/causes'
        },
        {
          label: 'Impact',
          url: '/impact'
        }
      ]
    }
  }
})
